/* Global Styles */
body {
  background-color: #ffffff;
}
.main-content {
    padding-top: 80px; /* Adjust this value based on your navbar height */
  }

/* Navbar Styles */
.navbar.navbar-custom {
  background-color: #3E50B4 !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  min-height: 64px;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  height: 38px;
}

.navbar-nav {
  gap: 0.5rem;
}

.navbar-nav > li > a.nav-link {
  color: #ffffff !important;
  opacity: 0.85;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.navbar-nav > li > a.nav-link:hover,
.navbar-nav > li > a.nav-link:focus,
.navbar-nav > .active > a.nav-link {
  color: #ffffff !important;
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

/* Dropdown styles */
.navbar-nav .dropdown-menu {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 0.5rem;
}

.navbar-nav .dropdown-item {
  color: #3E50B4;
  font-size: 14px;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
}

.navbar-nav .dropdown-item:hover,
.navbar-nav .dropdown-item:focus {
  background-color: rgba(62, 80, 180, 0.1);
  color: #3E50B4;
}

.navbar-nav .dropdown-divider {
  margin: 0.5rem 0;
  border-top: 1px solid rgba(62, 80, 180, 0.1);
}

/* Mobile navbar styles */
.navbar-toggler {
  border: none;
  padding: 0.5rem;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 991.98px) {
  .navbar-nav {
    padding: 1rem 0;
  }
  
  .navbar-nav > li > a.nav-link {
    padding: 0.75rem 1rem;
  }
  
  .navbar-nav .dropdown-menu {
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: none;
  }
  
  .navbar-nav .dropdown-item {
    color: #ffffff;
  }
  
  .navbar-nav .dropdown-item:hover,
  .navbar-nav .dropdown-item:focus {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
}

/* Drug Search Styles */
.drug-search-container {
  max-width: 800px;
  margin: 0 auto;
}

.search-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.search-card:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

#drug_balancer_logo {
  text-align: center;
  padding: 20px 0;
}

.drug-balancer-logo-img {
  height: 70px;
  max-width: 100%;
  transition: height 0.3s ease;
}

@media (max-width: 768px) {
  .drug-balancer-logo-img {
    height: 50px;
  }
}

@media (max-width: 480px) {
  #drug_balancer_logo {
    padding: 15px 0;
  }
  
  .drug-balancer-logo-img {
    height: 40px;
  }
}

/* Enhanced Custom Search Input with animations */
#custom-search-input {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(47, 62, 158, 0.15);
  border: solid 3px #3E50B4;
  overflow: hidden;
  transition: all 0.3s ease;
}

#custom-search-input:focus,
#custom-search-input:hover {
  box-shadow: 0 4px 8px 0 rgba(47, 62, 158, 0.24);
  border: solid 3px #2F3E9E;
  outline: none;
}

#custom-search-input:focus-within {
  border: solid 3px #3E50B4;
  box-shadow: 0 0 0 0.2rem rgba(62, 80, 180, 0.25);
  outline: none;
}

/* Style for the actual input field */
#input-search {
  border: none;
  box-shadow: none !important;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.2s ease;
}

#input-search::placeholder {
  font-weight: 500;
  color: #aaaaaa;
}

/* Override all focus states to remove any default browser/Bootstrap outline */
#input-search:focus,
#input-search:focus-within {
  box-shadow: none !important;
  outline: none !important;
  background-color: #f9f9ff;
  color: #3E50B4;
  border: none !important;
}

/* Explicitly remove form-control focus styling */
.form-control:focus {
  box-shadow: none !important;
  border-color: transparent !important;
  outline: 0 !important;
}

/* Properly style the container when input is focused */
#custom-search-input:focus-within {
  border-color: #3E50B4 !important;
  box-shadow: 0 0 0 0.2rem rgba(62, 80, 180, 0.25) !important;
}

/* Input group fix for focus state */
#custom-search-input .input-group {
  height: 100%;
  border: none;
}

#custom-search-input .input-group .form-control {
  height: 100%;
  border: none;
}

/* Complete override for Bootstrap form control focus states */
.form-control:focus {
  box-shadow: none !important;
  border-color: transparent !important;
  outline: 0 !important;
}

/* Override for the entire input group on focus */
.input-group:focus-within {
  outline: none !important;
  box-shadow: none !important;
}

/* Better button focus states */
.input-group .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

/* Remove the old rule completely */
#input-search:focus-within,
#custom-search-input:has(#input-search:focus) {
  /* rule removed */
}

/* Enhanced search buttons */
.input-group .btn {
  border: none;
  padding: 12px 16px;
  transition: all 0.2s ease;
}

.input-group .btn:hover {
  transform: scale(1.05);
}

.input-group .btn:active {
  transform: scale(0.95);
}

/* Specific styling for search and clear buttons */
.search-btn {
  background-color: #FEC006 !important;
  color: #3E50B4 !important;
  border-color: transparent !important;
  padding: 8px 16px !important;
  font-size: 1.2rem !important;
}

.search-btn:hover, .search-btn:focus {
  background-color: #f2b705 !important;
  color: #2F3E9E !important;
  box-shadow: 0 0 0 0.2rem rgba(254, 192, 6, 0.25) !important;
}

.clear-btn {
  background-color: #f8f9fa !important;
  color: #6c757d !important;
  border-color: transparent !important;
  padding: 8px 16px !important;
}

.clear-btn:hover, .clear-btn:focus {
  background-color: #e2e6ea !important;
  color: #495057 !important;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.1) !important;
}

/* Fix for input group styles with thick border */
.input-group {
  border-radius: 8px;
  overflow: hidden;
}

/* Fixes for the input group with thick border */
#custom-search-input .input-group {
  height: 100%;
  border: none;
}

#custom-search-input .input-group .form-control {
  height: 100%;
  border: none;
}

#custom-search-input .input-group .btn {
  border-radius: 0;
}

/* Tab styling - removed animations */
.search-mode-tabs {
  border-bottom: none;
  margin-bottom: 20px !important;
}

.search-mode-tabs .nav-item {
  margin-right: 5px;
}

.search-mode-tabs .nav-link {
  display: flex;
  align-items: center;
  border-radius: 6px 6px 0 0;
  padding: 10px 16px;
  font-weight: 500;
  color: #495057;
  border: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

.search-mode-tabs .nav-link.active {
  color: #3E50B4;
  border-color: #dee2e6 #dee2e6 #fff;
  background-color: #fff;
  font-weight: 600;
  border-top: 3px solid #3E50B4;
}

.search-mode-tabs .nav-link:hover {
  color: #3E50B4;
  border-top-color: #FEC006;
}

.search-mode-tabs .nav-link img {
  margin-right: 8px;
}

/* Removing all tab animations */
.search-mode-tabs .nav-link::before {
  display: none;
}

.search-mode-tabs .nav-link img.tab-icon {
  margin-right: 8px;
}

.search-mode-tabs .nav-link:hover img.tab-icon,
.search-mode-tabs .nav-link.active img.tab-icon,
.search-mode-tabs .nav-link:hover .tab-text,
.search-mode-tabs .nav-link.active .tab-text {
  transform: none;
}

/* Animation for tab content transition */
.tab-content {
  position: relative;
  animation: fadeInUp 0.3s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Updated styles for mobile devices */
@media (max-width: 576px) {
  .search-mode-tabs {
    flex-direction: row;
    justify-content: center;
  }

  .search-mode-tabs .nav-item {
    flex: 0 1 auto;
  }

  .search-mode-tabs .nav-link {
    flex-direction: row;
    text-align: center;
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
  }

  .search-mode-tabs .nav-link img {
    margin-right: 5px;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
  }
}

.input-group-btn button {
  border-radius: 1px;
  background-color: #FEC006;
  box-shadow: 0 0 0 1px rgba(47, 62, 158, 0.24);
  transition: all 0.2s ease;
}

.input-group-btn button:hover {
  background-color: #f2b705;
  transform: scale(1.05);
}

/* Animation for search results */
.search-results {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-tips {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
}

.search-tips h5 {
  color: #495057;
}

.search-tips ul {
  padding-left: 20px;
}

.drug-detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drug-icons {
  display: flex;
  gap: 10px;
}

.drug-icons > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Button Styles */
.btn-primary {
  background-color:  #3E50B4 !important;
  border-color:  #3E50B4;
  color:  #ffffff !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #3E50B4 !important;
  border-color: #3E50B4 !important;
  color: #FEC006 !important ;
}

.btn-secondary {
  background-color: #3E50B4;
  border-color: #3E50B4;
  color: #ffffff;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background-color: #FEC006 !important;
  border-color: #FEC006 !important;
  color: #3E50B4 !important;
}

/* Footer Styles */
.footer {
  background-color: #eaecf7;
  border-top: solid 8px #e5e7f0;
  padding: 10px 0;
}

.footer .text-muted {
  color: #747474;
  font-size: 15px;
  letter-spacing: 0.1px;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .navbar-brand {
    padding: 5px 15px 7px;
    max-width: 65%;
  }
  
  .navbar-brand img {
    max-height: 38px;
    max-width: 100%;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.5rem;
    margin-right: 10px;
  }
  
  .navbar-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-medkit.pull-right {
    margin-top: -35px;
  }
  
  .signin.pull-right {
    margin-top: -36px;
  }
}

@media (min-width: 768px) {
  .navbar-medkit.pull-right {
    margin-top: 12px;
  }
  
  .signin.pull-right {
    margin-top: 16px;
  }
}

@media (min-width: 992px) {
  .landing-content {
    padding: 60px;
  }
}

/* Drug Details Styles */
.drug-header {
  margin: 40px 0 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.75px;
  color: #2C3A8D;
}

.panel-title {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #2f3e9e;
  padding-top: 10px;
}

.panel-inner-header {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #060143;
  margin: 15px 0 10px;
}

.reg-text {
  letter-spacing: 0.3px;
  font-size: 16px;
}

.label-custom {
  color: #2f3e9e;
  background-color: transparent;
}

.btn-icon {
  outline: none;
  border: 0;
  color: #2C3A8D;
  background: transparent;
  vertical-align: middle;
}

.well-sm {
  background: #FFFFFD;
  font-size: 14px;
}

i.fa-info-circle {
  color: #FEC006;
}

.field-link {
  color: #3B5EBD;
}

.field-link:focus,
.field-link:hover {
  color: #3B5EBD;
}

.result-card-header {
    background-color: #3E50B4 !important;
  }

.search-results .list-group {
  max-height: 350px;
  overflow-y: auto;
}

.search-results .list-group-item {
  border-left: none;
  border-right: none;
}

.search-results .suggest-list-name {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: capitalize;
}

.search-results .list-group-item-action:hover {
  background-color: #f8f9fa;
}

.search-results mark {
  background-color: #ffeeba;
  padding: 0.1em 0.2em;
  border-radius: 2px;
}

.App .btn-primary,
.App .btn-secondary {
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}
.highlight p {
  background-color: #fff3cd;
  padding: 5px;
  border-radius: 3px;
  transition: all 0.3s ease;
  box-shadow: 0 0 5px rgba(254, 192, 6, 0.5);
}

[dir="ltr"] .highlight p {
  border-left: 3px solid #FEC006;
}

[dir="rtl"] .highlight p {
  border-right: 3px solid #FEC006;
}

.highlight:hover p {
  background-color: #ffeeba;
  box-shadow: 0 0 8px rgba(254, 192, 6, 0.7);
}

.section-total {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #495057;
  }
  .footer {
    padding: 2rem 0;
  }
  
  .footer ul {
    margin-bottom: 0;
  }
  
  .footer li {
    margin-bottom: 0.5rem;
  }
  
  .footer .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footer .col-auto {
    padding: 0 1rem;
  }
  
  @media (max-width: 767.98px) {
    .footer .col-auto {
      flex-basis: 50%;
      margin-bottom: 1.5rem;
    }
  }

  /* Link styles */
a {
  color: #3E50B4 !important;
  transition: color 0.3s ease;
}

a:hover,
a:focus {
  color: #FEC006 !important;
  text-decoration: none;
}

/* Checkbox styles */
/* For default checkboxes */
input[type="checkbox"]:checked {
  background-color: #3E50B4 !important;
  border-color: #3E50B4 !important;
}

/* For Bootstrap's custom checkboxes */
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #3E50B4 !important;
  background-color: #3E50B4 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
}

/* Focus styles for accessibility */
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(62, 80, 180, 0.25) !important;
}

/* Style for focus state of links */
a:focus {
  outline: 2px solid rgba(62, 80, 180, 0.5) !important;
  outline-offset: 2px;
}

/* Pagination Styles */
.pagination .page-item .page-link {
  color: #3E50B4;
  background-color: #ffffff;
  border-color: #3E50B4;
  transition: all 0.3s ease;
}

.pagination .page-item .page-link:hover {
  color: #ffffff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}

.pagination .page-item.active .page-link {
  color: #ffffff;
  background-color: #3E50B4;
  border-color: #3E50B4;
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
  background-color: #ffffff;
  border-color: #dee2e6;
}

/* Add this to your main CSS file */
.reference-item {
  scroll-margin-top: 100px; /* Adjust this value based on your navbar height */
}

.contact-container {
    text-align: center;
    padding: 20px;
  }
  
  .contact-details {
    margin-bottom: 20px;
  }
  
  .contact-images img {
    max-width: 100%;
    height: auto;
    margin: 10px;
  }

/* Navbar Alert Styles */
.navbar-custom .alert {
  margin-bottom: 0;
  border-radius: 4px;
}

.navbar-custom .alert small {
  color: inherit;
}

.navbar-custom .alert .btn-link {
  color: inherit;
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
}

.navbar-custom .alert .btn-link:hover {
  text-decoration: none;
}

.modal-content {
  background-color: #000;
}

.modal-header {
  border-bottom: none;
  padding: 1rem;
}

.modal-header .btn-close {
  color: #fff;
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal-title {
  color: #fff;
}